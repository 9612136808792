import { Container } from "@material-ui/core"
import React, { FunctionComponent } from "react"
import styled from "styled-components"
import { v4 as uuidv4 } from "uuid"
import PortfolioCard from "./PortfolioCard"

interface IportfolioCard {
  img?: string
  name?: string
  desc?: string
  portfolioInfo?: any
}

interface portfolioGridProps {
  portfolios: any
}

// type IUniversities = Array<IportfolioCard>

const PortfolioCardGrid: FunctionComponent<portfolioGridProps> = ({ portfolios }) => {
  



  return (
    <Container maxWidth={"xl"}>
      <PortfoliosCardContainer>
        {portfolios.map((item, index) => {
            let image = null
            try{
               image = item.student_profile.searchImage.localFile.childImageSharp.fluid
            }catch(e){
              // console.log(e)
            }
          return (
            <PortfolioCard
              key={uuidv4()}
              name={item.student_profile.studentName}
              desc={item.student_portfolio.aboutMeHeading}
              slug={item.slug}
              tags={item.student_profile.specialism}
              img={image}
            />
          )
        })}
      </PortfoliosCardContainer>
    </Container>
  )
}

export default PortfolioCardGrid

const PortfoliosCardContainer = styled.div`
  margin: 0 auto;
  margin-top: 3rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  max-width: 1291px;

  /* max-width: 1440px; */
  margin-bottom: 50px;

  @media (max-width: ${props => props.theme.breakpoints.lg}) {
    padding: 15px;
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    padding: 0px;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 0px;
    grid-template-columns: repeat(1, 1fr);
  }
`
