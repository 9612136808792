import styled from "styled-components"
import { Link } from "gatsby"

export enum SizeType {
  small,
  medium,
  large,
}

interface BtnProps {
  size?: SizeType
  customwidth?: string
  inactive?: boolean
  onClick?: () => void
  to?: string
}

export const FormButton = styled.div<BtnProps>`
  background: ${props => props.theme.colors.turquoise};
  height: ${props => (props.size === SizeType.small ? "40px" : props.size === SizeType.medium ? "50px" : "60px")};
  min-width: ${props =>
    props.size === SizeType.small
      ? props.customwidth || "125px"
      : props.size === SizeType.medium
      ? props.customwidth || "165px"
      : props.customwidth || "300px"};
  width: ${props => props.customwidth};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  font-size: ${props => (props.size === SizeType.small ? "14px" : props.size === SizeType.medium ? "20px" : "22px")};
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
  border: 1px solid ${props => props.theme.colors.turquoise};
  transition: background 0.3s ease;
  &:hover {
    background-color: ${props => props.theme.colors.turquoiseHover};
    border-color: ${props => props.theme.colors.turquoiseHover};
    color: ${props => props.theme.tints.white};
    text-decoration: none;
  }
`

export const ButtonSecondary = styled(FormButton)`
  background: ${props => props.theme.tints.white};
  color: ${props => props.theme.colors.secondary};
  border-color: ${props => props.theme.colors.secondary};
  &:hover {
    background-color: ${props => props.theme.colors.secondary};
    border-color: ${props => props.theme.colors.secondary};
  }
`
