import React, { FunctionComponent, useEffect, useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

export enum SizeType {
  small,
  medium,
  large,
}

interface BtnProps {
  label: string
  checked: boolean
  onChange: any
  value: any
}

export const ToggleButton: FunctionComponent<BtnProps> = ({ label, checked, onChange, value }) => {
  function handleClick() {
    onChange(value, !checked)
  }

  return (
    <Button onClick={() => handleClick()} isActive={checked}>
      {label}
    </Button>
  )
}

interface BtnProps {
  isActive: boolean
}

const Button = styled.div<BtnProps>`
  background: ${props => (props.isActive ? props.theme.colors.turquoise : "white")};

  height: 50px;
  margin: 0 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  padding: 0 20px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  color: ${props => (props.isActive ? "white" : "black")};
  cursor: pointer;
  border: 1px solid ${props => props.theme.colors.secondaryBorder};
  transition: background 0.3s ease;
  &:hover {
    background-color: ${props => props.theme.colors.turquoiseHover};
    border-color: ${props => props.theme.colors.turquoiseHover};
    color: ${props => props.theme.tints.white};
    text-decoration: none;
  }
`

export const ToggleButtonSecondary = styled(ToggleButton)`
  background: ${props => props.theme.tints.white};
  color: ${props => props.theme.colors.secondary};
  border-color: ${props => props.theme.colors.secondary};
  &:hover {
    background-color: ${props => props.theme.colors.secondary};
    border-color: ${props => props.theme.colors.secondary};
  }
`

const Label = styled.p<BtnProps>`
  color: ${props => (props.isActive ? "white" : "black")};

  &:hover {
    color: white;
  }
`
