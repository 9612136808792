import React from "react"
import { Layout } from "../components/layout"
import { graphql } from "gatsby"
import { LazyBlockFactory } from "../util/LazyBlockFactory"
import PageHeading from "../components/PageHeading/PageHeading"

import { Container } from "@material-ui/core"

import PortfolioFilter from "../components/PortfoliosTemplate/PortfolioFilter"

const headings = ["Accessory Design", "Atelier Design", "Social Media", "Award Winner", "Beauty Promotion"]

const breadcrumbData = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Search Portfolios",
    url: "/search-portfolios",
  },
]

const SearchPortfolios: React.FC = ({ data }) => {
  const portfoliosData = data.allWpPortfolio.edges.map(v => v.node)

  const blocks = LazyBlockFactory(data.wpPage.lazy_data, data.wpPage.title)

  const portfolios = portfoliosData.sort(() => Math.random() - 0.5)
  // const portfolios = portfoliosData

  return (
    <Layout title={"Graduate Fashion Week - Search Portfolio"}>
    <PageHeading
      breadcrumbData={breadcrumbData}
      //  content={content}
      pageTitle="Search Portfolios"
    />
 
    <Container maxWidth={"lg"}>{blocks.map(v => v)}</Container>
    <PortfolioFilter data={portfolios} />
  </Layout>
   

   
  )
}

export default SearchPortfolios

export const pageQuery = graphql`
  query portfolios {
    site {
      siteMetadata {
        amplifyBranch
      }
    }
    wpPage(title: { eq: "Portfolio Listing" }) {
      id
      lazy_data
      title
    }
    allWpPortfolio {
      edges {
        node {
          id
          title
          slug
          student_portfolio {
            aboutMeHeading
          }
          student_profile {
            studentName
            specialism
            yearOfGraduation
            searchImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 400) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
    {/* {data.site.siteMetadata.amplifyBranch !== "master" ? (
        <Layout title={"Graduate Fashion Week - Search Portfolio"}>
          <PageHeading
            breadcrumbData={breadcrumbData}
            //  content={content}
            pageTitle="Search Portfolios"
          />
       
          <Container maxWidth={"lg"}>{blocks.map(v => v)}</Container>
          <PortfolioFilter data={portfolios} />
        </Layout>
      ) : ( 
        <Layout title={"Graduate Fashion Week - Search Portfolio"}>
          <SearchPortFoliosHeader
            breadcrumb={breadcrumbData}
            title={"EXPLORE OUR 2021 GRADUATES"}
            intro={
              "Head back this May to scout talent from over 1500+ graduate portfolio profiles from universities around the world. Our hub allows you, the industry, to view the very best curated graduate work, with addtional search options for over 40 specialisms making it easier for you to refine and find the best hire for you."
            }
            catUrl={"/"}
            tags={[]}
          />
        </Layout>
      )} */}