import { Checkbox, Container, Drawer, IconButton, makeStyles } from "@material-ui/core"
import React, { FunctionComponent, useEffect, useLayoutEffect, useState } from "react"
import styled from "styled-components"
import { FormButton, ButtonSecondary } from "../Buttons/FormButtons"
import { ToggleButton } from "../Buttons/ToggleButton"
import MenuIcon from "@material-ui/icons/Menu"
import ClearIcon from "@material-ui/icons/Clear"

import Search from "../../assets/svg/SearchBlue.svg"
import PortfolioCardGrid from "./PortfolioCardGrid"
import _ from "lodash"

import RightArrow from "../../assets/svg/long-arrow-right.svg"
import { Sepcialisms } from "./specialisms"
import { Link } from "gatsby"

const useStyles = makeStyles(theme => ({
  drawer: {
    width: "540px",
    maxWidth: "100%",
    zIndex: 99,
  },
}))
interface IportfolioCard {
  img?: string
  name?: string
  desc?: string
  portfolioInfo?: any
}
interface portfolioFlierProps {
  data: any
}

// type IUniversities = Array<IportfolioCard>

// Sorry!
type IUniversities = any

const PortfolioFilter: FunctionComponent<portfolioFlierProps> = ({ data }) => {
  const year = new Date().getFullYear()
  const studentYears = [year, year - 1, year - 2]
  const classes = useStyles()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [filtersSelected, setFiltersSelected] = useState([])
  const [numberOfSelected, setNumberOfSelected] = useState(0)
  const [displayFiltered, setDisplayFiltered] = useState([])
  const [resetChecks, setResetChecks] = useState(false)
  const [nameOfSelected, setNameOfSelected] = useState([])
  const [specialitiesObject, setSpecialitiesObject] = useState(Sepcialisms.map(v => false))
  const [yearObject, setYearObject] = useState(studentYears.map(v => false))
  const [yearToFilter, setYearToFilter] = useState(0)
  const [numberOfSpecialismsToShow, setNumberOfSpecialismsToShow] = useState(5)
  const [numberOfPortfoliosToShow, setNumberOfPortfoliosToShow] = useState(16)

  const [dataFilteredByYear, setDataFilteredByYear] = useState(data.sort(() => Math.random() - 0.5))
  const [shuffledData, setShuffledData] = useState(
    dataFilteredByYear.sort((a, b) =>
      a.student_profile.yearOfGraduation < b.student_profile.yearOfGraduation ? 1 : -1
    )
  )

  // useEffect(() => {
  //   setShuffledData(
  //     dataFilteredByYear.sort((a, b) =>
  //       a.student_profile.yearOfGraduation < b.student_profile.yearOfGraduation ? 1 : -1
  //     )
  //   )
  // }, [dataFilteredByYear])

  useEffect(() => {
    let isMounted = true
    let portfoliosY = []
    if (isMounted) {
      switch (yearToFilter) {
        case 0:
          portfoliosY = data.sort(() => Math.random() - 0.5)
          break

        case year:
          data.map((item, index) => {
            if (item.student_profile.yearOfGraduation == year) {
              portfoliosY.push(item)
            }
          })
          break
        case year - 1:
          data.map((item, index) => {
            if (item.student_profile.yearOfGraduation == year - 1) {
              portfoliosY.push(item)
            }
          })
          break
        case year - 2:
          data.map((item, index) => {
            if (item.student_profile.yearOfGraduation == year - 2) {
              portfoliosY.push(item)
            }
          })
          break
      }
    }
    setDataFilteredByYear(portfoliosY)
    return () => {
      isMounted = false
    }
  }, [yearToFilter])


  useLayoutEffect(() => {
    if (window.innerWidth >= 1200) {
      setNumberOfSpecialismsToShow(4)
    } else if (window.innerWidth < 1200) {
      setNumberOfSpecialismsToShow(2)
    }
  }, [])

  function handleShowMore() {
    setDrawerOpen(!drawerOpen)
  }

  useEffect(() => {
    //reset checks
    let isMounted = true
    if (isMounted && resetChecks) {
      const tempspecialitiesObject = []
      const tempYearsObject = []
      Sepcialisms.map((title, index) => {
        tempspecialitiesObject.push(false)
      })
      studentYears.map((title, index) => {
        tempYearsObject.push(false)
      })

      setSpecialitiesObject(tempspecialitiesObject)
      setResetChecks(false)
      setNumberOfSelected(0)
      setYearToFilter(0)
      setYearObject(tempYearsObject)
    }
    return () => {
      isMounted = false
    }
  }, [resetChecks])
  
  useEffect(() => {
    let indexofSelected = []
    let isMounted = true
    if (isMounted) {
      if (numberOfSelected >= 1) {
        const nameSelected = [] //array of the name values of each specialism
        filtersSelected.map((item, index) => {
          //array of index values of each specialism
          if (item) {
            //if the item selected from the boolean list
            indexofSelected.push(index)
          }
        })
        indexofSelected.map((item, index) => {
          nameSelected.push(Sepcialisms[item])
        })
        setNameOfSelected(nameSelected) // creates usestate array of
        const map = new Map()
        for(let i = 0; i< shuffledData.length; i++){
          // if any of the names selected exist in the specialism array. Append
          for(let l =0; l< nameSelected.length; l++){
            const specs = shuffledData[i].student_profile.specialism
            if(specs.includes(nameSelected[l])){
                map.set(shuffledData[i].id,shuffledData[i])
            }
          }

        }
       const result = Array.from(map.values())
        setDisplayFiltered(result)
      } else {
        setDisplayFiltered(shuffledData)
      }
    }
    return () => {
      isMounted = false
    }
  }, [filtersSelected, numberOfSelected, yearObject])


  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      setFiltersSelected(specialitiesObject)
    }
    return () => {
      isMounted = false
    }
  }, [specialitiesObject])


  // Hanlde the toggling of specialisms
  const handleCheck = e => {
    const tempSO = _.cloneDeep(specialitiesObject)
    tempSO[e.target.value] = e.target.checked
    const selectedSpecs = tempSO.filter(Boolean).length
    setNumberOfSelected(selectedSpecs)
    setSpecialitiesObject(tempSO)
  }

  const handleToggle = (value, newValue) => {
    const tempSO = _.cloneDeep(specialitiesObject)
    tempSO[value] = newValue
    const selectedSpecs = tempSO.filter(Boolean).length
    setNumberOfSelected(selectedSpecs)
    setSpecialitiesObject(tempSO)
  }

  const handleYear = (value, newValue) => {
    let tempYearsObject = []

    studentYears.map(i => {
      tempYearsObject.push(false)
    })
    const index = studentYears.indexOf(parseInt(value))
    tempYearsObject[index] = !tempYearsObject[index]

    setYearToFilter(value)
    setYearObject(tempYearsObject)
  }

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      if (numberOfSelected === 0) {
        setDisplayFiltered(shuffledData)
      }
    }
    return () => {
      isMounted = false
    }
  }, [numberOfSelected, dataFilteredByYear])

  return (
    <Container maxWidth={"xl"}>
      <StyledDrawer anchor={"right"} open={drawerOpen} classes={{ paper: classes.drawer }}>
        <DrawTitleContainer>
          <DrawerHeader>
            <h5>Filter Graduates By: </h5>

            <IconButton onClick={() => handleShowMore()}>
              {drawerOpen ? <StyledClearIcon /> : <StyledMenuIcon />}
            </IconButton>
          </DrawerHeader>
        </DrawTitleContainer>
        <DrawTitleContainer></DrawTitleContainer>

        {Sepcialisms.map((title, index) => {
          return (
            <DrawTitleContainer key={"speacialties-container-" + index}>
              <SpecialitiesContainer >
                {title}
                <Checkbox
                  checked={specialitiesObject[index]}
                  onChange={e => handleCheck(e)}
                  value={index}
                  style={{
                    color: "#4F9F31",
                  }}
                  key={index}
                  inputProps={{ "aria-label": title }}
                />
              </SpecialitiesContainer>
            </DrawTitleContainer>
          )
        })}
        <DrawTitleContainer>
          <SpecialitiesContainer key={"speacialties-container-addedspace"}>{""}</SpecialitiesContainer>
        </DrawTitleContainer>
        <DrawTitleContainer>
          <SpecialitiesContainer key={"speacialties-container-addedspaceholder"}>{""}</SpecialitiesContainer>
        </DrawTitleContainer>
        <DrawTitleContainer>
          <SpecialitiesContainer key={"speacialties-container-addedspaceholder"}>{""}</SpecialitiesContainer>
        </DrawTitleContainer>

        <DrawerFooter>
          <ButtonSecondary size={1} onClick={() => setResetChecks(true)}>
            {" "}
            CLEAR FILTERS
          </ButtonSecondary>
          <FormButton size={1} onClick={() => setDrawerOpen(false)}>
            {" "}
            APPLY FILTERS {numberOfSelected}{" "}
          </FormButton>
        </DrawerFooter>
      </StyledDrawer>

      <SearchBar>
        <SearchSection to={"/search"}>
          <div>
            <SearchIcon />
          </div>
          <span>Search for Graduate</span>
        </SearchSection>
        <ButtonContainer>
          <ToggleButton
            label={"All"}
            key={"all-toggle"}
            checked={numberOfSelected === 0}
            onChange={() => setResetChecks(true)}
            value={"all"}
          ></ToggleButton>

          {/* {studentYears.map((item, index) => {
            return (
              <ToggleButton
                label={item.toString()}
                key={"year-toggle" + item.toString()}
                checked={yearObject[index]}
                onChange={handleYear}
                value={item}
              ></ToggleButton>
            )
          })} */}

          {Sepcialisms.slice(0, numberOfSpecialismsToShow).map((title, index) => {
            return (
              <ToggleButton
                label={title}
                key={index}
                checked={specialitiesObject[index]}
                onChange={handleToggle}
                value={index}
              ></ToggleButton>
            )
          })}
        </ButtonContainer>
        <LinkContainer onClick={() => handleShowMore()}>
          <ShowAllSpan>SHOW ALL FILTERS</ShowAllSpan>
          <StyledRightArrow />
        </LinkContainer>
      </SearchBar>

      <PortfolioCardGrid
        portfolios={
          displayFiltered
            ? displayFiltered.slice(0, numberOfPortfoliosToShow)
            : shuffledData.slice(0, numberOfPortfoliosToShow)
        }
      />
      <ButtonContainer2 onClick={() => setNumberOfPortfoliosToShow(numberOfPortfoliosToShow + 8)}>
        <ButtonSecondary customwidth={"250px"}>REVEAL MORE GRADUATES</ButtonSecondary>
      </ButtonContainer2>
    </Container>
  )
}

export default PortfolioFilter
const SearchIcon = styled(Search)`
  height: 24px;
  width: 24px;
  color: "#0DB0E7";
  position: relative;
  top: 5px;
  left: -5px;
`

const SearchSection = styled(Link)`
  min-width: 150px;
  color: #4a4a4a;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    justify-content: left;
  }
`

const StyledDrawer = styled(Drawer)`
  max-width: 100% !important;
  max-height: 100% !important;
`
const LinkContainer = styled.div`
  color: ${props => props.theme.colors.turquoise};
  display: flex;
  flex-direction: row;
  align-items: center;
  min-width: 150px;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
    justify-content: right;
  }
`

const ShowAllSpan = styled.span`
  color: #0db0e7;
  font-size: 16px;
  font-weight: 500;
  min-width: 110px;
  line-height: 26px;
`
const StyledRightArrow = styled(RightArrow)``
const DrawerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;

  border-bottom: 1px solid #cbcbcb;
`
const StyledClearIcon = styled(ClearIcon)`
  width: 30px !important;
  height: 30px !important;

  color: ${props => props.theme.colors.turquoise} !important;
`
const StyledCheckbox = styled(Checkbox)``

const DrawTitleContainer = styled.div`
  height: 50px;
  padding: 10px 40px;
`
const StyledMenuIcon = styled(MenuIcon)`
  width: 30px !important;
  height: 30px !important;

  color: ${props => props.theme.colors.turquoise} !important;
`
const SpecialitiesContainer = styled.div`
  margin: 20px 0px 0;
  padding: 0 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const DrawerFooter = styled.div`
  position: fixed;
  bottom: 0;
  background-color: black;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 23px 0;
  width: 100%;
  max-width: 540px;
`

const SearchBar = styled(Container)`
  margin-top: 50px;
  padding-bottom: 20px;
  border-bottom: 1px solid #cbcbcb;
  display: flex;
  flex-direction: row;
  @media (max-width: 765px) {
    flex-direction: column;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  column-gap: 5px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    justify-content: space-between;
    width: 100%;
    margin: 10px 0px;
  }
`
const ButtonContainer2 = styled.div`
  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto 60px;
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    width: 100%;
  }
`
