import React from "react"
import Breadcrumb from "../Breadcrumb"
import styled from "styled-components"
import { Container } from "@material-ui/core"

interface Item {
  title: string
  url: string
}
type IBreadcrumb = Array<Item>

interface IPageHeading {
  breadcrumbData: IBreadcrumb
  pageTitle: string
}

const PageHeading: React.FC<IPageHeading> = ({ breadcrumbData, pageTitle }) => {
  return (
    <>
      <FullWidth>
        <BreadCrumbContainer>
          <Breadcrumb data={breadcrumbData} />
        </BreadCrumbContainer>
        <PageTitlePattern>
          <PageTitleContainer>
            <h1>{pageTitle}</h1>
          </PageTitleContainer>
        </PageTitlePattern>
      </FullWidth>
    </>
  )
}

export default PageHeading

const FullWidth = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  background-color: #fafafa;
`

const BreadCrumbContainer = styled(Container)`
  width: 100vw;
  justify-content: center;
  align-items: center;
  z-index: 10;
`
const PageTitlePattern = styled.div`
  width: 100vw;
  background-color: #fafafa;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUAgMAAADw5/WeAAAACVBMVEX6+vr4+Pjv7++npPQmAAAAKElEQVQI12NgCGBgZWBgEGBgZACzYWTo0tCoUAiJIs7gwMCCi02CXgD5agzBt66q6wAAAABJRU5ErkJggg==");
  background-repeat: repeat;
`
const PageTitleContainer = styled(Container)`
  justify-content: center;
  align-items: center;
  z-index: 10;
  margin-bottom: 50px;
  margin-top: 50px;
`
