export const Sepcialisms = [
  "Accessory Design",
  "Art Direction",
  "Atelier Design",
  "Award Nominee",
  "Award Winner",
  "Beauty Promotion",
  "Branding",
  "Business & Management",
  "Buying",
  "Childrenswear",
  "Digital Media",
  "Disabled Fashion",
  "Diversity",
  "Eco / Zero Waste Design",
  " Fashion Design",
  "Fashion Graphics",
  "Fashion Image",
  "Fashion Journalism",
  "Fashion Publication",
  "Fashion Technology",
  "Filmmaker",
  "Footwear Design",
  "Gender Neutral",
  "Heritage & Culture",
  "Illustration",
  "International Fashion",
  "Knitwear Design",
  "Makeup & Hair Design",
  "Marketing",
  "Menswear",
  "Merchandising",
  "Modestwear",
  "Photography",
  "Plus Size",
  "PR & Communications",
  "Promotion",
  "Retail Management",
  "Social Media",
  "Sportswear & Leisurewear",
  "Styling",
  "Sustainabilty",
  "Textiles",
  "Womenswear",
]
